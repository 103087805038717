import React, { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { checkTokenValidity } from "./actions/authActions";
import Login from "./components/Login/Login";
import { isLoggedIn } from './actions/authActions';
import RoutesComponent from "./RoutesComponent";
import Loader from "./Common/Loader";
import { useLoadingContext } from "./Loading/LoadingContext";
import Header from "./Header/Header";

const App = () => {
  const dispatch = useDispatch();
  const LoginLoading = useSelector(state => state.auth.loading);
  const isAuthenticated = isLoggedIn();
  const { isLoading } = useLoadingContext();

  useEffect(() => {
    dispatch(checkTokenValidity());
  }, [dispatch]);

  return (
    <div className="App">
    {LoginLoading && <Loader />}
    {isLoading && <Loader/>}
    {isAuthenticated ? (
      <>
        <Header />
        <RoutesComponent />
      </>
    ) : (
      <Login />
    )}
  </div>
  );
};

export default App;