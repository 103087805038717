import { showMessage } from "../Utils/showMessageUtils";
import api from "../Utils/api";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CHECK_TOKEN_VALIDITY,
  LOGOUT,
} from "../actionTypes";
import Cookies from "js-cookie";
import {
  encryptAndSetItemLocalStorage ,
  removeEncryptLocalStorage ,
  decryptAndGetItemLocalStorage ,
} from "../Utils/cryptosessionStorage";

export const login = (credentials) => async (dispatch) => {
  
  dispatch({ type: LOGIN_REQUEST });
  try {
    const response = await api.post(
      "/ClientLogin/CheckClientLoginOTP",
      credentials
    );

    if (response.data.statusCode === 200) {
      const { token, mobileNo } = response.data.data;
      encryptAndSetItemLocalStorage ("token", token);
      encryptAndSetItemLocalStorage ("LoginMobileNo", mobileNo);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { token: token, userName: mobileNo, userId: 0 },
      });
      window.location.reload(false);
      return response.data;
    } else {
      showMessage(400,response.data.message);
      dispatch({
        type: LOGIN_FAILURE,
        error: response.data.message || "Login failed",
      });
    }
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
      error: error.response?.data?.message || "Login failed",
    });
  }
};

export const checkTokenValidity = () => async (dispatch) => {
  const token = decryptAndGetItemLocalStorage ("token");
  if (!token) {
    dispatch(Logout());
    return false;
  }
  try {
    const response = await api.post(`Login/IsTokenExpired?token=${token}`);
    if (response.data.data) {
      dispatch({ type: CHECK_TOKEN_VALIDITY, payload: true });
      return true;
    } else {
      dispatch(Logout());
      window.location.href = "/logout";
      return false;
    }
  } catch (error) {
    dispatch(Logout());
    window.location.href = "/logout";
    return false;
  }
};

export const Logout = () => (dispatch) => {
  removeEncryptLocalStorage ("token");
  removeEncryptLocalStorage ("LoginMobileNo");
  removeEncryptLocalStorage ("LoginUserEmail");
  removeEncryptLocalStorage ("OnePolicyID");
  removeEncryptLocalStorage ("_MNO_C");
  removeEncryptLocalStorage ("_EI0D0C");
  dispatch({ type: LOGOUT });
};

export const isLoggedIn = () => {
  return !!decryptAndGetItemLocalStorage ("token");
};
