import axios from "axios";
import data from "../UserSettings.json";
import { showMessage } from "./showMessageUtils";

const api = axios.create({
  baseURL: data.apiUrl,
});

const notifyError = (message) => showMessage(400,message);
export const handleApiError = (error) => {
  notifyError("An error occurred.");
};

export default api;
