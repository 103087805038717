import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import LOGO from "../assets/logo.png";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import api from "../Utils/api";
import Swal from "sweetalert2";
import { useLoadingContext } from "../Loading/LoadingContext";
import {
  decryptAndGetItemLocalStorage,
  encryptAndSetItemLocalStorage,
} from "../Utils/cryptosessionStorage";
import { showMessage } from "../Utils/showMessageUtils";

export default function Header({ userName }) {
  const ClientMobileNo = decryptAndGetItemLocalStorage("_MNO_C") || "";
  const ClientEmailId = decryptAndGetItemLocalStorage("_EI0D0C") || "";
  const [mobileNo, setMobileNo] = useState(
    decryptAndGetItemLocalStorage("LoginMobileNo") || ""
  );
  const [emailID, setEmailID] = useState(
    decryptAndGetItemLocalStorage("LoginUserEmail") || ""
  );
  const { setIsLoading } = useLoadingContext();
  const [permissionForEditing, setPermissionForEditing] = useState(false);

  const EditPermisionGenerateOTP = () => {
    // setIsLoading(true);
    // api
    //   .post(
    //     `ClientLogin/GenerateOTPAtClientChangeMobileAndEmail?MobileNo=${ClientMobileNo}&EmailId=${ClientEmailId}`
    //   )
    //   .then((result) => {
    //     showMessage(result.data.statusCode, result.data.message);
    //     if (result.data.statusCode === 200) {
    //       setPermissionForEditing(true);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     showMessage(400, "An error occurred.");
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //     setPermissionForEditing(true);
    //   });
    setPermissionForEditing(true);
  };

  const SendOTPToNewNumberEndEmail = () => {
    if (mobileNo.length !== 10) {
      showMessage(400, "Enter Valid Mobile No!");
      return;
    }
    setIsLoading(true);
    api
      .post(
        `ClientLogin/GenerateOTPAtClientChangeMobileAndEmail?MobileNo=${mobileNo}&EmailId=${emailID}`
      )
      .then((result) => {
        showMessage(result.data.statusCode, result.data.message);
        if (result.data.statusCode === 200) {
          handelMobileAndEmailChange();
        }
      })
      .catch((error) => {
        console.error(error);
        showMessage(400, "An error occurred.");
      })
      .finally(() => {
        setIsLoading(false);
        setPermissionForEditing(true);
      });
  };

  const handelMobileAndEmailChange = async () => {
    const result = await Swal.fire({
      title: "Enter OTP For Change EmailID And MobileNo",
      input: "number",
      inputPlaceholder: "Enter your OTP",
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: (password) => {
        if (!password) {
          Swal.showValidationMessage(`OTP is required`);
        }
        return password;
      },
    });

    if (result.isConfirmed) {
      setIsLoading(true);
      api
        .get(
          `Policy/HandleChangeMobileNoAndEmailByClient?clientMobileNo=${ClientMobileNo}&clientEmailId=${ClientEmailId}&newMobileNo=${mobileNo}&newEmailID=${emailID}&changeOTP=${result.value}`
        )
        .then((result) => {
          if (result.data.statusCode == 200) {
            encryptAndSetItemLocalStorage(
              "LoginMobileNo",
              result.data.data.newMobileNo
            );
            encryptAndSetItemLocalStorage(
              "LoginUserEmail",
              result.data.data.newEmailID
            );
            encryptAndSetItemLocalStorage(
              "_MNO_C",
              result.data.data.finalMobileNo
            );
            encryptAndSetItemLocalStorage(
              "_EI0D0C",
              result.data.data.finalEmailID
            );
            showMessage(200, result.data.message);
            window.location.reload();
          } else {
            showMessage(400, result.data.message);
          }
        })
        .catch((result) => {
          showMessage(400, "An error occurred.");
        })
        .finally(() => {
          setIsLoading(false);
          setPermissionForEditing(false);
        });
    } else {
      setMobileNo(decryptAndGetItemLocalStorage("LoginMobileNo"));
      setEmailID(decryptAndGetItemLocalStorage("LoginUserEmail"));
      setPermissionForEditing(false);
    }
  };

  return (
    <Navbar expand="lg" className="custom-bg-color">
      <Container fluid>
        <Navbar.Brand href="#">
          <NavLink className="navbar-brand logo_back" to="/">
            <img
              src={LOGO}
              alt="TAPTI TECHNOLOGY"
              loading="lazy"
              style={{ display: "inline-flex", height: "30px" }}
            />
          </NavLink>
        </Navbar.Brand>

        <Navbar.Collapse id="navbarScroll" className="w=full">
          <Row className="w-full TextBoxRow">
            <Col sm={12} md={3} lg={3} className="TextBoxCol">
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-mobile"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="MobileNo"
                  value={mobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                  disabled={!permissionForEditing && true}
                />
              </InputGroup>
            </Col>
            <Col sm={12} md={6} lg={5} className="TextBoxCol">
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-envelope"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="EmailID"
                  value={emailID}
                  onChange={(e) => setEmailID(e.target.value)}
                  disabled={!permissionForEditing && true}
                />
              </InputGroup>
            </Col>
            <Col sm={12} md={2} lg={2} className="justify-center TextBoxCol">
              {permissionForEditing ? (
                <Button
                  variant="outline-warning"
                  onClick={SendOTPToNewNumberEndEmail}
                >
                  Change
                </Button>
              ) : (
                <Button
                  variant="outline-warning"
                  // onClick={() => setPermissionForEditing(true)}
                  onClick={EditPermisionGenerateOTP}
                >
                  Edit
                </Button>
              )}
            </Col>
          </Row>
        </Navbar.Collapse>
        <div className="d-flex justify-content-end ">
          <NavLink className="navbar-brand" to="/logout">
            <i className="fas fa-sign-out-alt"></i> Logout
          </NavLink>
        </div>
        <Navbar.Toggle aria-controls="navbarScroll" />
      </Container>
    </Navbar>
  );
}
