import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import UserKeys from "../UserKeys.json";

const { DEFAULT_KEY, DEFAULT_IV, DEFAULT_PROJECT } = UserKeys;

// Encryption function with prefixed header
export const encryptAndSetItemLocalStorage = (header, value) => {
  const prefixedHeader = `${DEFAULT_PROJECT}_${header}`;

  const encryptedValue = CryptoJS.AES.encrypt(
    value,
    CryptoJS.enc.Utf8.parse(DEFAULT_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(DEFAULT_IV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();

  const encryptedHeader = CryptoJS.AES.encrypt(
    prefixedHeader,
    CryptoJS.enc.Utf8.parse(DEFAULT_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(DEFAULT_IV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();

  // Cookies.set(encryptedHeader, encryptedValue, { expires: 1 });
  sessionStorage.setItem(encryptedHeader, encryptedValue);
};

// Decryption function with prefixed header
export const decryptAndGetItemLocalStorage = (header) => {
  const prefixedHeader = `${DEFAULT_PROJECT}_${header}`;

  const encryptedHeader = CryptoJS.AES.encrypt(
    prefixedHeader,
    CryptoJS.enc.Utf8.parse(DEFAULT_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(DEFAULT_IV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();

  // const encryptedValue = Cookies.get(encryptedHeader);
  const encryptedValue = sessionStorage.getItem(encryptedHeader);
  if (!encryptedValue) {
    return null; // Handle case where encrypted value is not found
  }

  const decryptedBytes = CryptoJS.AES.decrypt(
    encryptedValue,
    CryptoJS.enc.Utf8.parse(DEFAULT_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(DEFAULT_IV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decryptedBytes.toString(CryptoJS.enc.Utf8);
};

// Removal function with prefixed header
export const removeEncryptLocalStorage = (header) => {
  const prefixedHeader = `${DEFAULT_PROJECT}_${header}`;

  const encryptedHeader = CryptoJS.AES.encrypt(
    prefixedHeader,
    CryptoJS.enc.Utf8.parse(DEFAULT_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(DEFAULT_IV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();
  sessionStorage.removeItem(encryptedHeader);
};
