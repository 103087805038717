import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Card, ListGroup, Table, Row, Col } from "react-bootstrap";
import { useCurrentPolicyContext } from "../../actions/CurrentPolicyContext";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { getSingleData } from "../../Utils/CallApiUtils";
import { convertDateFormatWithOutTime } from "../../Utils/commonFunction";
import UserSetting from "../../UserSettings.json";
import downloadPdfIcon from "../../assets/icons8-download-pdf-48.png";

export default function Endorsement() {
  const { currentSelectPolicyID, setCurrentSelectPolicyID } =
    useCurrentPolicyContext();
  const [endorsementData, setEndorsementData] = useState([]);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (currentSelectPolicyID > 0) {
      getEndorsementDetail();
    } else {
      window.location = "/";
    }
  }, [currentSelectPolicyID]);

  const getEndorsementDetail = async () => {
    await getSingleData(
      `Endorsement/AllEndorsementDataDetailModel?PolicyID=${currentSelectPolicyID}`,
      setEndorsementData,
      setIsLoading
    );
  };
  return (
    <>
    
      <div className="">
        {endorsementData.length > 0 ?  <Card.Body className="scrollable-card-body">
          {endorsementData.map((item) => (
            <Card style={{ border: "2px solid #6366f1" }} className="projcard">
              <Card.Header
                style={{
                  backgroundColor: "#20336b",
                  color: "white",
                  fontSize: "18px",
                  padding: "10px",
                }}
              >
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    {" "}
                    <span>PolicyNo:-{item.policyNo}</span>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    className="lg:text-end md:text-end"
                  >
                    <span>Endorsement Name :-{item.endorsementName}</span>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row className="d-flex justify-content-between lg:p-2 md:p-2">
                  <Col
                    sm={12}
                    md={6}
                    lg={3}
                    style={{ padding: window.innerWidth < 768 && "5px" }}
                  >
                    <span>
                      <strong>Endorsement Date:&nbsp;</strong>
                      {convertDateFormatWithOutTime(item.endorsementDate)}
                    </span>
                  </Col>
                  {window.innerWidth < 768 && <hr />}
                </Row>
                <Row className="d-flex justify-content-between lg:p-2 md:p-2 ">
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    style={{ padding: window.innerWidth < 768 && "5px" }}
                  >
                    <span>
                      <strong>Premium Amount:&nbsp;</strong>₹{item.premiumAmt}
                    </span>
                  </Col>
                  {window.innerWidth < 768 && <hr />}
                  <Col
                    sm={12}
                    md={4}
                    lg={4}
                    className="lg:text-center md:text-center "
                    style={{ padding: window.innerWidth < 768 && "5px" }}
                  >
                    <span>
                      <strong> Service Tax:&nbsp;</strong> {item.serviceTax}(₹
                      {item.serviceTaxAmount})
                    </span>
                  </Col>
                  {window.innerWidth < 768 && <hr />}
                  <Col
                    className="lg:text-end md:text-end"
                    sm={12}
                    md={4}
                    lg={4}
                    style={{ padding: window.innerWidth < 768 && "5px" }}
                  >
                    <span>
                      <strong>Net Amount:&nbsp;</strong>₹{item.netAmount}
                    </span>
                    {/* {window.innerWidth < 768 && <hr />} */}
                  </Col>
                </Row>
                <Row>
                    <NavLink
                      to={UserSetting.imgURL + item.imagePath}
                      target="_blank"
                      className="text-end flex justify-end "
                    >
                      {" "}
                      <img src={downloadPdfIcon} alt="" className="w-8 h-8" />
                    </NavLink>
                  </Row>
              </Card.Body>
            </Card>
          ))}
        </Card.Body>
        :
        <p>Not Any Endorsement In This Policy</p>
        }
      </div> 
    </>
  );
}
