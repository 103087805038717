import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CHECK_TOKEN_VALIDITY,
  LOGOUT,
  IS_LOADING,
} from "../actionTypes";

const initialState = {
  isAuthenticated: false,
  token: null,
  userName: null,
  userId: null,
  loading: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        userName: action.payload.userName,
        userId: action.payload.userId,
        loading: false,
        error: null,
      };
    case LOGIN_FAILURE:
      return { ...state, loading: false, error: action.error };
    case CHECK_TOKEN_VALIDITY:
      return { ...state, isAuthenticated: action.payload };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        userName: null,
        userId: null,
      };
    case IS_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default authReducer;
