import Swal from "sweetalert2";

export const showMessage = (StatusCode, Message) => {
    
    if (StatusCode === 200) {
        Swal.fire({
            icon: "success",
            title: Message,
            showConfirmButton: false,
            timer: 1000,
            showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `
              },
              hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `
              }
          });
    } else if (StatusCode === 400 || StatusCode === 404) {
        Swal.fire({
            icon: "error",
            title: Message,
            showConfirmButton: false,
            timer: 1500,
            showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `
              },
              hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `
              }
          });
    }
    else if (StatusCode === 401) {
      Swal.fire({
          icon: "error",
          title: Message,
          showConfirmButton: false,
          timer: 1500,
          showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            }
        });
  }
     else {
        Swal.fire({
            icon: "error",
            title: "An unexpected error occurred",
            showConfirmButton: false,
            timer: 1500,
            showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `
              },
              hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `
              }
          });
    }
  };