import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/authActions";
import Loader from "../../Common/Loader";
import Logo from "../../assets/loginlogo.png";
import api from "../../Utils/api";
import { useLoadingContext } from "../../Loading/LoadingContext";
import {encryptAndSetItemLocalStorage ,decryptAndGetItemLocalStorage } from "../../Utils/cryptosessionStorage";
import { showMessage } from "../../Utils/showMessageUtils";

const Login = () => {
  const [MobileNo, setMobileNo] = useState("");
  const [showOTPPart, setShowOTPPart] = useState(false);
  const [OTP, setOTP] = useState(new Array(6).fill(""));
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.loading);
  const { setIsLoading } = useLoadingContext();
  const [countdown, setCountdown] = useState(300);
  const [showNewOTP, setShowNewOTP] = useState(false);
  const [MobilNoValidation, setMobilNoValidation] = useState(false);
  const [OTPValidation, setOTPValidation] = useState(false);
  // const isAuthenticated = isLoggedIn();

  useEffect(() => {
    let timer;
    if (showOTPPart && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      showMessage(400,"OTP has expired. Please request a new OTP.");
      setShowNewOTP(true);
      setOTP(new Array(6).fill(""));
    }
    return () => clearInterval(timer);
  }, [showOTPPart, countdown]);

  const validateMobileNo = () => {
    if (!MobileNo || MobileNo.length !== 10) {
      setMobilNoValidation(true);
      return false;
    } else {
      setMobilNoValidation(false);
      return true;
    }
  };


  const validateOfOTP = (otpValue) => {
    
    if (!otpValue || otpValue.toString().length !== 6) {
      setOTPValidation(true);
      return false;
    } else {
      setOTPValidation(false);
      return true;
    }
  };

  const handleSubmit = async (e) => {
    debugger
    e.preventDefault();
    if (validateMobileNo()) {
      setIsLoading(true);
      try {
        const result = await api.post(
          `ClientLogin/CheckClientLogin?MobileNo=${MobileNo}`
        );
        showMessage(result.data.statusCode,result.data.message)
        if (result.data.statusCode === 200) {
          setShowOTPPart(true);
          encryptAndSetItemLocalStorage ("LoginMobileNo",result.data.data.mobileNo);
          encryptAndSetItemLocalStorage ("LoginUserEmail",result.data.data.emailIdTo);
          encryptAndSetItemLocalStorage ("OnePolicyID",result.data.data.policyencvalue)
          encryptAndSetItemLocalStorage ("_MNO_C",result.data.data.finalMobileNo);
          encryptAndSetItemLocalStorage ("_EI0D0C",result.data.data.finalEmailID);
        }
      } catch (error) {
        showMessage(400,"An error occurred")
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFinalSubmit = async (e) => {
    if (countdown > 0) {
      const otpValue = parseInt(OTP.join(""));
      if(validateOfOTP(otpValue)){
        dispatch(login({ OTP : otpValue }));
      } 
    } else {
      showMessage(400,"OTP has expired. Please request a new OTP.");
    }
  };

  const handleGenerateOTP = async (e) => {
    debugger
    let Nu = decryptAndGetItemLocalStorage ("_MNO_C")
    setIsLoading(true);
    try {
      const result = await api.post(
        `ClientLogin/GenerateNewOTP?MobileNo=${Nu}`
      );
      if (result.data.statusCode === 200) {
        setCountdown(300);
        setShowNewOTP(false);
      } else {
        showMessage(400,result.data.message);
      }
    } catch (error) {
      showMessage(400,"An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOTPChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOTP([...OTP.map((d, idx) => (idx === index ? element.value : d))]);
    setOTPValidation(false);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };



  return (
    <div className="flex h-screen bg-indigo-700">
      <div className="w-full max-w-xs m-auto bg-indigo-100 rounded p-3">
        <header>
          <img className="w-40 mx-auto mb-5 mt-3" src={Logo} alt="Logo" />
        </header>
        {isLoading && <Loader />}
        {showOTPPart === false && (
          <form>
            <div className="mb-10">
              <label className="block mb-2 text-indigo-500 font-bold" htmlFor="username">
                Mobile No
              </label>
              <input
                className="w-full p-2  text-indigo-700 border-b-2 rounded-md  border-indigo-500 outline-none focus:bg-gray-300"
                type="number"
                name="MobileNo"
                placeholder="MobileNo"
                value={MobileNo}
                onChange={(e) => setMobileNo(e.target.value)}
                style={{
                  '-webkit-appearance': 'none',
                  '-moz-appearance': 'textfield',
                }}
              />
              {MobilNoValidation && (
                <p className="text-red-500 text-sm font-bold">Enter Valid MobileNo!</p>
              )}
            </div>
            <div>
              <button
                className="w-full bg-indigo-700 hover:bg-pink-700 text-white font-bold py-2 px-4 mb-6 rounded"
                type="bUTTON"
                onClick={handleSubmit}
              >
                Generate OTP
              </button>
            </div>
          </form>
        )}
        {showOTPPart && (
          <form>
            <div>
              <label className="block mb-2 text-indigo-500 font-bold" htmlFor="username">
                Enter OTP
              </label>
              {/* <input
                className="w-full p-2 mb-6 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300 LoginMobileInput"
                type="number"
                name="OTP"
                placeholder="OTP"
                value={OTP}
                onChange={(e) => setOTP(e.target.value)}
              /> */}
              <div className="flex justify-between">
                {OTP.map((data, index) => {
                  return (
                    <input
                      className="w-10 p-2 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300 text-center"
                      type="text"
                      name="otp"
                      maxLength="1"
                      key={index}
                      value={data}
                      onChange={(e) => handleOTPChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  );
                })}
               
              </div>
               {OTPValidation && (
               <p className="text-red-500 text-sm font-bold">Enter Valid 6 Digit OTP!</p>
              )}
            </div>
            <div className="text-center mb-4 mt-2  text-indigo-700 font-semibold">
              {Math.floor(countdown / 60)}:{countdown % 60 < 10 ? "0" : ""}
              {countdown % 60} minutes remaining
            </div>
            <div>
              {!showNewOTP && (
                <button
                  className="w-full bg-indigo-700 hover:bg-pink-700 text-white font-bold py-2 px-4 mb-6 rounded"
                  type="button"
                  onClick={handleFinalSubmit}
                >
                  Login
                </button>
              )}
              {showNewOTP && (
                <button
                  className="w-full bg-indigo-700 hover:bg-pink-700 text-white font-bold py-2 px-4 mb-6 rounded"
                  type="button"
                  onClick={handleGenerateOTP}
                >
                  Generate OTP
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
