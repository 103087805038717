import React from "react";
import api,{handleApiError} from "./api";
import axios from "axios";



export function convertDateFormatWithOutTime(dateString) {


  const dateObject = new Date(dateString);

  // Extract date, month, year, hours, and minutes
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  const formattedDate = `${day}-${month}-${year} `;

  return formattedDate;
}


export function convertDateFormatWithSeconds(dateString) {
  const dateObject = new Date(dateString);

  // Extract date, month, year, hours, minutes, and seconds
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  let hours = dateObject.getHours();
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');
  const seconds = dateObject.getSeconds().toString().padStart(2, '0');
  let ampm = 'AM';

  // Convert 24-hour time to 12-hour time and determine AM/PM
  if (hours >= 12) {
    ampm = 'PM';
    if (hours > 12) {
      hours -= 12;
    }
  }

  // Handle midnight (0 hours) case
  if (hours === 0) {
    hours = 12;
  }

  // Format the date string with seconds
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;

  return formattedDate;
}


export function convertDateFormat(dateString) {


  const dateObject = new Date(dateString);

  // Extract date, month, year, hours, and minutes
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  let hours = dateObject.getHours();
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');
  let ampm = 'AM';

  // Convert 24-hour time to 12-hour time and determine AM/PM
  if (hours > 12) {
    hours -= 12;
    ampm = 'PM';
  }

  // Format the date string
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;

  return formattedDate;
}

export const getCurrentDateTime = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");

  return `${day}-${month}-${year}T${hours}:${minutes}`;
}


export const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: value % 1 === 0 ? 0 : 0,
  }).format(value);

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const GetFutureDate = ()=>{
  const today = new Date();
  const year = today.getFullYear() + 1 ;

  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate() - 1).padStart(2, "0");
  return `${year}-${month}-${day}`;
}


export const DayDifference = (Expected) => {
 
  const today = new Date();
  const currentDate = today.toISOString().split('T')[0];
  const ExpectedDate = Expected.split('T')[0];

  const timeDifference = new Date(currentDate) - new Date(ExpectedDate);

  const totalDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

  return totalDays < 0 ? `${Math.abs(totalDays)} Days Remaining` : `${totalDays} Days OverDue`;
};


export const WhatsAppStatus = () => {
  
  return api
      .get(`SMSApi/WhatsAppStatus`)
      .then(({ data }) => data)
      .catch(handleApiError);
};


export const CheckRoleTemplatePermissions = (formName) => {
  return api
    .get(`RoleTemplateDetail/CheckRoleTemplatePermissions?FormName=${formName}`)
    .then((response) => response.data.data)
    .catch((error) => {
      console.error('Error fetching role template permissions:', error);
      return null;
    });
};

