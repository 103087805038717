import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Card, Table, Row, Col } from "react-bootstrap";
import { useCurrentPolicyContext } from "../../actions/CurrentPolicyContext";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { getSingleData } from "../../Utils/CallApiUtils";
import { convertDateFormatWithSeconds } from "../../Utils/commonFunction";
export default function Courier() {
  const { currentSelectPolicyID, setCurrentSelectPolicyID } =
    useCurrentPolicyContext();
  const [CourierData, setCourierData] = useState([]);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (currentSelectPolicyID > 0) {
      getCourierDetail();
    } else {
      window.location = "/";
    }
  }, [currentSelectPolicyID]);

  const getCourierDetail = async () => {
    await getSingleData(
      `Courier/AllCourierDetailModel?PolicyID=${currentSelectPolicyID}`,
      setCourierData,
      setIsLoading
    );
  };
  return (
    <div className="">
      {CourierData.length > 0 ? (
        <Card.Body className="scrollable-card-body">
          <Row>
            {CourierData.map((item) => (
              <Col sm={12} md={6} lg={4}>
                <Card
                  style={{ border: "2px solid #6366f1" }}
                  className="projcard p-0"
                >
                  <Card.Body className="p-0">
                    <Table bordered className="CourierTable m-0">
                      <tbody>
                        <tr>
                          <td>
                            <strong>PolicyNo</strong>
                          </td>
                          <td>{item.policyNo}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Tracking Number</strong>
                          </td>
                          <td>{item.trackingNumber}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Tracking Link</strong>
                          </td>
                          <td>
                            <a
                              href={`http://www.anjanicourier.in/Doc_Track.aspx?No=${item.trackingNumber}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-700 underline"
                            >
                              Track your shipment
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Dispatched Date</strong>
                          </td>
                          <td>
                            {convertDateFormatWithSeconds(
                              item.dispatchedDateTime
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Status</strong>
                          </td>
                          <td>
                            {item.courierStatus}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Courier Name</strong>
                          </td>
                          <td>{item.courierName}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      ) : (
        <p>Not Any Courier In This Policy</p>
      )}
    </div>
  );
}
