import React, { createContext, useContext, useState } from "react";

const CurrentPolicyContext = createContext();

export const useCurrentPolicyContext = () => useContext(CurrentPolicyContext);

export const CurrentPolicyProvider = ({ children }) => {
  const [currentSelectPolicyID, setCurrentSelectPolicyID] = useState(0);

  return(
    <CurrentPolicyContext.Provider value={{currentSelectPolicyID , setCurrentSelectPolicyID}}>
        {children}
    </CurrentPolicyContext.Provider>
  )
};
