import React,{useState} from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { isLoggedIn, Logout } from './actions/authActions'; // Import Logout action
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import { useDispatch } from "react-redux";
import PageNotFound from "./reducers/Protected";
import Claim from "./components/Claim/Claim";
import Endorsement from "./components/Endorsement/Endorsement";
import Courier from "./components/Courier/Courier";

const HandleLogout = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(Logout());
    window.location.href = "/";
  };
  React.useEffect(() => {
    handleLogout();
  }, []);
  return null;
};

export default function RoutesComponent() {
  const isAuthenticated = isLoggedIn();
  const [loading, setLoading] = useState(true);
 
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/Claim" element={<Claim />} />
      <Route path="/Endorsement" element={<Endorsement />} />
      <Route path="/Courier" element={<Courier />} />
       <Route path="/logout" element={<HandleLogout />} />
       <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
